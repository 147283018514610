<template>
    <div class="center-bottom-wrapper">
        <div class="operate-line">
            <span @click="toggle('total', 0, '总量')" :class="[type === 'total' ? 'active' : 'tag-block']">总量</span>
            <span @click="toggle('kitchenWaste', 1, '厨余')" :class="[type === 'kitchenWaste' ? 'active' : ' tag-block']">厨余垃圾</span>
            <span @click="toggle('other', 2, '其他')" :class="[type === 'other' ? 'active' : ' tag-block']">其他垃圾</span>
            <span @click="toggle('recyclable', 3, '可回收物')" :class="[type === 'recyclable' ? 'active' : ' tag-block']">可回收物</span>
            <span @click="toggle('harmful', 4, '有害垃圾')" :class="[type === 'harmful' ? 'active' : ' tag-block']">有害垃圾</span>
        </div>
        <div id="month-chart" style="width: 100%;height: 350px;" ref="monthChart"></div>
    </div>
</template>

<script>
var myChart;
import DATA_CONFIG from '../../../config/dataConfg'
import { getTypeMonthApi } from '@/api/today'
import * as echarts from 'echarts'
export default {
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            type: 'total',
            index: 0,
            typeName: '总量',
            option: {},
            color: [],
            dates: [],
            chartData: {
                total: [],
                kitchenWaste: [],
                other: [],
                recyclable: [],
                harmful: []
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.getTypeMonth()
            setInterval(() => {
                this.getTypeMonth()
            }, 10000)
        }, 200)
        let that = this
        window.onresize = () => {
            setTimeout(() => {
                that.$refs.monthChart.resize();
                window.location.reload()
                setInterval(() => {
                    that.$refs.monthChart.resize();
                    window.location.reload()
                }, 10000)
            }, 1000)
        
        };
    
    },
    destroyed() {
        window.onresize = null;
    },
    methods: {
        getTypeMonth() {
            getTypeMonthApi({moduleCode: this.moduleCode}).then(res => {
                this.chartData = {...res.result}
                this.dates = []
                this.chartData.kitchenWaste.forEach(item => {
                    this.dates.push(item.date)
                })
                this.color = (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors : DATA_CONFIG.colors
                this.drawChart()
            })
            .catch(() => {
                this.chartData = {
                    kitchenWaste:[],
                    total:[],
                    other:[],
                    recyclable:[],
                    harmful:[],
                }
                for(let i = 0; i < 30; i++) {
                    this.dates.push(i+1)
                    this.chartData.kitchenWaste.push({date: i+1, value: 0})
                    this.chartData.total.push({date: i+1, value: 0})
                    this.chartData.other.push({date: i+1, value: 0})
                    this.chartData.recyclable.push({date: i+1, value: 0})
                    this.chartData.harmful.push({date: i+1, value: 0})
                }
                this.drawChart()

            })
            .finally(() => {
                // this.drawChart()
            })
        },
        toggle(type, i, name) {
            this.type = type
            this.index = i
            this.typeName = name
            this.drawChart()
        },
        drawChart() {
            myChart = document.getElementById('month-chart')
            myChart = echarts.init(myChart);
            var data = []
            this.chartData[this.type].forEach(item => {
                data.push(item.value)
            })
            this.option = {
                title: {
                    text: '单位(KG)',
                    textStyle: {
                        color: '#fff',
                        fontSize: 13
                    },
                    left: 0
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    data: this.dates,
                    axisLabel: {
                        inside: false,
                        textStyle: {
                            color: '#fff',
                            fontSize: 15
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',
                            fontSize: 15

                        }
                    },
                    splitLine: {
                        show:false,
                        lineStyle: {
                            color:"#fff",
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        type: 'bar',
                        itemStyle: {
                            color: this.color[this.index]
                        },
                        emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#2378f7'},
                                        {offset: 0.7, color: '#2378f7'},
                                        {offset: 1, color: '#83bff6'}
                                    ]
                                )
                            }
                        },
                        data: data
                    }
                ]
            };
            myChart.setOption(this.option)
        }
    }
}
</script>

<style lang="scss">
.center-bottom-wrapper{
    width: 100%;
    height: 100%;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .operate-line{
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        .active{
            color: #fff;
            background: rgba(38, 86, 202, 1);
            width: 68px;
            text-align: center;
        }
        .tag-block{
            width: 68px;
            text-align: center;
            &:hover{
                cursor: pointer;
            }
        }
    }
}
</style>