<template>
     <div class="right-top-wrapper">
         <div class="operate-line">
             <span>{{date}} 0:00 ~ 24:00</span>
             <span @click="toggle('today_all')" :class="[type === 'today_all' ? 'active' : 'tag-block']">全部</span>
             <span @click="toggle('today_morning')" :class="[type === 'today_morning' ? 'active' : ' tag-block']">上午</span>
             <span @click="toggle('today_afternoon')" :class="[type === 'today_afternoon' ? 'active' : ' tag-block']">下午</span>
         </div>
         <div class="pie-row">
            <div id="pie-chart" style="width: 45%;height: 200px;" ref="pieChart"></div>
            <a-row style="width:47%;height: 200px;">
                <a-col :span="24" class="pie-row-right" >
                    <a-row :gutter="20" v-for="(item, index) in chartData" :key="index" style="margin-top: 10px;">
                        <a-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24"><span :class="[`${item.type}`]" :style="{'background': item.color}"></span>{{item.name}}</a-col>
                        <a-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">{{item.percentage}}%</a-col>
                    </a-row>
                </a-col>
            </a-row>
         </div>
         <div class="divide-line"> <div class="box-left"></div><div class="box-right"></div></div>
         <div style="width: 100%;height: 200px;padding-top:15px;color:#fff;">
            <a-row :gutter="20" style="height: 45px;width: 100%;" v-for="(item, index) in chartData" :key="index">
                <a-col :xl="6" :lg="6" :md="12" :sm="24" :xs="24">
                    {{item.name}}<br/>
                    <span style="color: #00FBFF;font-size:18px;">{{item.value}}KG</span>
                </a-col>
                <a-col :xl="18" :lg="18" :md="12" :sm="24" :xs="24">
                    <div class="progress">
                    <div class="bar" :style="{'width': item.percentage + '%', 'background': item.color}"></div>  {{item.percentage}}%
               </div>
                </a-col>
            </a-row>
         </div>
     </div>
</template>

<script>
import moment from 'moment'
import DATA_CONFIG from '../../../config/dataConfg'
import { getTypeDataApi } from '@/api/today'
import * as echarts from 'echarts'
export default {
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            date: '',
            type: 'today_all',
            chartData: [
                {value: 0, name: '厨余垃圾', type: 'cook',  color: '#5b6378', percentage: 0},
                {value: 0, name: '其他垃圾', type: 'other',  color: '#1f8efa', percentage: 0},
                {value: 0, name: '可回收物', type: 'recycle',  color: '#05c985', percentage: 0},
                {value: 0, name: '有害垃圾', type: 'danger', color: '#ff6b6b', percentage: 0},
            ],
            totalWeight: 0.00,
            colors: []
        }
    },
    mounted() {
        this.date = moment(new Date()).format('yyyy-MM-DD')
        setTimeout(() => {
            this.getTypeData(this.type)
            setInterval(() => {
                this.getTypeData(this.type)
            }, 10000)
        }, 200)
        window.onresize = () => {
            setTimeout(() => {
                that.$refs.pieChart.resize();
                window.location.reload()
                setInterval(() => {
                    that.$refs.pieChart.resize();
                    window.location.reload()
                }, 10000)
            }, 1000)
         
        };
    },
    methods: {
        getTypeData(type) {
            getTypeDataApi({moduleCode: this.moduleCode, time: type}).then(res => {
                this.totalWeight = res.result.totalWeight
                res.result.typeData.forEach((item, index) => {
                    this.chartData[index].value = item.weight
                    this.chartData[index].percentage = parseInt(item.percentage)
                })
                this.colors = (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors : DATA_CONFIG.colors
                this.chartData.forEach((item, index) => {
                    item.color = this.colors[index + 1]
                })
                this.drawChart()
            }).catch(() => {
                this.$message.error('数据接收异常')
                this.drawChart()
            })
        },
        toggle(type) {
            this.type = type
            this.getTypeData(this.type)
        },
        drawChart() {
            let myChart = echarts.init(document.getElementById('pie-chart'))
            let option = {
                color: this.colors.slice(1, 6),
                graphic: [
                    {
                        type: 'group',
                        top: 'middle',
                        left: 'center',
                        id: 'data',
                        children: [
                            {
                                type: 'text',
                                id: 'current',
                                top: 10,
                                style: {
                                    text: this.totalWeight + 'KG',
                                    // font: 'bolder 4em "Microsoft YaHei", sans-serif',
                                    fill: '#fff',
                                    textAlign: 'center'
                                }
                            }, 
                            {
                                type: 'text',
                                id: 'all',
                                top: 40,
                                style: {
                                    text: '总量',
                                    // font: 'bolder 2em "Microsoft YaHei", sans-serif',
                                    fill: '#c0c0c0',
                                    textAlign: 'center'
                                }
                            }
                        ]
                    }
                ],
                series: [
                    {
                        name: '轮次',
                        type: 'pie',
                        radius: ['50%', '80%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.chartData
                    }
                ]
            }
            myChart.setOption(option)
        }
    }
}
</script>

<style lang="scss">
.right-top-wrapper{
    width: 100%;
    height: 100%;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .redot{
		display:block;
        background: #ff6b6b;
	  	border-radius:50%;
	  	width:7px;
	  	height:7px;
	  	top:8px;
	  	left:-9px;
	  	position:absolute;
	}
      .cook{
            background: #5b6378;
            display:block;
            border-radius:50%;
            width:7px;
            height:7px;
            top:8px;
            left:-9px;
            position:absolute;
        }
        .other{
            background: #1f8efa;
            display:block;
            border-radius:50%;
            width:7px;
            height:7px;
            top:8px;
            left:-9px;
            position:absolute;
        }
        .recycle{
            background: #05c985;
            display:block;
            border-radius:50%;
            width:7px;
            height:7px;
            top:8px;
            left:-9px;
            position:absolute;
        }
        .danger{
            background: #ff6b6b;
            display:block;
            border-radius:50%;
            width:7px;
            height:7px;
            top:8px;
            left:-9px;
            position:absolute;
        }
    .operate-line{
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .active{
            color: #fff;
            background: rgba(38, 86, 202, 1);
            width: 68px;
            text-align: center;
        }
        .tag-block{
            width: 68px;
            text-align: center;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .pie-row{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .pie-row-right{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
    }
    .divide-line{
        width: 100%;
        height: 1px;
        background: rgba(26, 81, 183, 0.63);
        position: relative;
        .box-left{
            width: 26px;
            height: 26px;
            position: absolute;
            bottom: -1.5px;
            left: -1.5px;
            border-bottom: 2px solid #00FBFF;
        }
        .box-right{
            width: 26px;
            height: 26px;
            position: absolute;
            bottom: -1.5px;
            right: -1.5px;
            border-bottom: 2px solid #00FBFF;
        }
    }
    .progress {
      border-radius: 0px  !important;
	  overflow: hidden;
	  height: 17px;
	//   margin-bottom: 20px;
	  margin-top: 20px;
	//   margin-left: 30px;
	  background-color: #2e3657;
	  background-image: -moz-linear-gradient(top, #2e3657, #2e3657);
	  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2e3657), to(#2e3657));
	  background-image: -webkit-linear-gradient(top, #2e3657, #2e3657);
	  background-image: -o-linear-gradient(top, #2e3657, #2e3657);
	  background-image: linear-gradient(to bottom, #2e3657, #2e3657);
	  background-repeat: repeat-x;
	  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
	  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	  -webkit-border-radius: 4px;
	  -moz-border-radius: 4px;
	//   border-radius: 4px;
	//   width:408px;
	  margin-right:12px;
	}
	
	.progress .bar {
      margin-right: 10px;
	  width: 0%;
	  height: 100%;
	  color: #ffffff;
	  float: left;
	  font-size: 12px;
	  text-align: center;
	  align-items: center;
	  display: flex;
	  justify-content: center;
	  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	  background-color: #0e90d2;
	  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
	  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
	  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
	  background-image: -o-linear-gradient(top, #149bdf, #0480be);
	  background-image: linear-gradient(to bottom, #149bdf, #0480be);
	  background-repeat: repeat-x;
	  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
	  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	  -webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	  box-sizing: border-box;
	  -webkit-transition: width 0.6s ease;
	  -moz-transition: width 0.6s ease;
	  -o-transition: width 0.6s ease;
	  transition: width 0.6s ease;
	}
}
</style>