<template>
    <div class="com-box-container ">
        <div class="box-1 "></div>
        <div class="box-2"/>
        <div class="box-3"/>
        <div class="box-4"/>
        <div class="box-title" v-if="title"><div class="box-title-border"><span class="title">{{title}}</span></div></div>
        <div class="box-content" >
            <center-actual-data v-if="type === 'left-top'" ref="actual" :moduleCode="moduleCode"/>
            <main-statistics v-if="type === 'center-top'"  ref="main" :moduleCode="moduleCode" :regionName="regionName"/>
            <right-top v-if="type === 'right-top'"  ref="rightTop" :moduleCode="moduleCode" :regionName="regionName"/>
            <left-bottom v-if="type === 'left-bottom'"  ref="leftBottom" :moduleCode="moduleCode" :regionName="regionName"/>
            <center-bottom v-if="type === 'center-bottom'"  ref="centerBottom" :moduleCode="moduleCode" :regionName="regionName"/>
            <right-bottom v-if="type === 'right-bottom'"  ref="rightBottom" :moduleCode="moduleCode" :regionName="regionName"></right-bottom>
            <house-hold v-if="type === 'house-hold'"  ref="houseHold" :moduleCode="moduleCode" :regionName="regionName"></house-hold>
            <!-- <tools v-if="type === 'toggle-sys'"  class="toggle-sys"></tools> -->
        </div>
    </div>
</template>

<script>
// import tools from './tools'
import centerBottom  from './centerBottom'
import rightTop from './rightTop'
import CenterActualData from './centerActualData.vue'
import mainStatistics from './mainStatistics'
import leftBottom from './leftBottom.vue'
import rightBottom from '@/components/viewport/component/rightBottom'
import houseHold from '@/components/viewport/component/household'
export default {
    components: { CenterActualData, mainStatistics, rightTop , leftBottom, centerBottom, rightBottom, houseHold},
    props: {
        type: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    mounted() {

    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
@import '@/assets/variableCss.scss';

.com-box-container{
    border: 1px solid #003E7E;
    width: 100%;
    height: 100%;
    position: relative;
    margin: 10px 0px 0 5px;
    box-shadow: 0px 0px 7px 0px rgba(26, 81, 183, 0.35), 0px 0px 5px 0px rgba(26, 81, 183, 0.5);
    .box-content{
        width: 100%;
        height: 100%;
        padding: 40px 20px;
        box-sizing: border-box;
        overflow: hidden;
        .toggle-sys{
            padding: 10px 20px;text-align: center;font-size: 15px;color: aqua;
        }
    }
    .box-title{
        // border-image: url('../../../assets/image/box-title-border.png') 27 ;
        width: 50%;
        height: 40px;
        // border: 10px solid transparent;
        padding: 2px;
        text-align: center;
        position: absolute;
        top: -20px;
        left: 25.5%;
        background: $global-background-color;
        .box-title-border{
            width: 100%;
            height: 100%;
            border: 4px solid #fff ;
            -moz-border-image: url('../../../assets/image/box-title-border.png')  60/24px stretch;
            -webkit-border-image: url('../../../assets/image/box-title-border.png')  60/24px stretch;
            -o-border-image: url('../../../assets/image/box-title-border.png')  60/24px stretch;
            border-image: url('../../../assets/image/box-title-border.png') 60/24px stretch ;
            // border-image-width: 3px 24px;
            display: flex;
            flex-direction: columns;
            justify-content: center;
        }
        .title{
            align-items: center;
            color: #00FBFF;
            font-size: 18px;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
    .box-1{
        width: 26px;
        height: 26px;
        position: absolute;
        top: -1.5px;
        left: -1.5px;
        border-left: 2px solid $box-angel-highligt;
        border-top: 2px solid $box-angel-highligt;
    }
    .box-2{
        width: 26px;
        height: 26px;
        position: absolute;
        top: -1.5px;
        right: -1.5px;
        border-right: 2px solid $box-angel-highligt;
        border-top: 2px solid $box-angel-highligt;
    }
    .box-3{
        width: 26px;
        height: 26px;
        position: absolute;
        bottom: -1.5px;
        left: -1.5px;
        border-bottom: 2px solid $box-angel-highligt;
        border-left: 2px solid $box-angel-highligt;
    }
    .box-4{
        width: 26px;
        height: 26px;
        position: absolute;
        bottom: -1.5px;
        right: -1.5px;
        border-right: 2px solid $box-angel-highligt;
        border-bottom: 2px solid $box-angel-highligt;
    }
}
</style>