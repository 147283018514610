export default {
    moudleCfg: {
        '厨余垃圾': 'kitchen',
        '其他垃圾': 'other',
        '园林绿化': 'gardens',
        '大件垃圾': 'bulky',
        '加工颗粒': 'particles'
    },
    moduleCode: '143574462027141120',
    colors: ['#da732c', '#5b6378', "#1f8efa", "#05c985", "#ff6b6b"],
    cs_colors: ['#da732c', '#05c985', "#5b6378", "#1f8efa", "#ff6b6b"],

}