<template>
    <div class="left-bottom-wrapper">
        <div class="operate-line">
            <span @click="toggle('all',0)" :class="[type === 'all' ? 'active' : 'tag-block']">总量</span>
            <span @click="toggle('kitchenWaste',1)" :class="[type === 'kitchenWaste' ? 'active' : ' tag-block']">厨余垃圾</span>
            <span @click="toggle('other',2)" :class="[type === 'other' ? 'active' : ' tag-block']">其他垃圾</span>
            <span @click="toggle('recyclable',3)" :class="[type === 'recyclable' ? 'active' : ' tag-block']">可回收物</span>
            <span @click="toggle('harmful',4)" :class="[type === 'harmful' ? 'active' : ' tag-block']">有害垃圾</span>
        </div>
        <div id="street-chart" style="width: 100%;height: 320px;"></div>
    </div>
</template>

<script>
import DATA_CONFIG from '../../../config/dataConfg'
import { getTodayStreetApi }  from '@/api/today'
var myChart;
import * as echarts from 'echarts'
export default {
    data() {
        return {
            type: 'all',
            index: 0,
            streetName: [],
            streetValues: [],
            colors: [],
            currentColor: '#da732c'
        }
    },
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.getTodayStreet()
            setInterval(() => {
                this.getTodayStreet()
            }, 10000)
        }, 200)
    },
    destroyed() {
        window.onresize = null;
    },
    methods: {
        getTodayStreet() {
            getTodayStreetApi({moduleCode: this.moduleCode, typeCode: this.type}).then(res => {
                this.streetName  = res.result.streetName
                this.streetValues = res.result.totalWeight
                this.colors = (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors : DATA_CONFIG.colors
                this.drawChart(this.streetName, this.streetValues)
            })
            .catch(() => {
                this.drawChart(['暂无街道数据'], [0])
            })
            .finally(() => {
                window.onresize = () => {
                    myChart.resize();
                };
            })
        },
        toggle(type, i) {
            this.type = type
            this.index = i
            this.currentColor = this.colors[i]
            this.getTodayStreet()
        },
        drawChart(names, values) {
            let attackSourcesData = values //柱状图值 --- 数据列表
            let attackSourcesName = names//街道列表
        
            let that = this
            function attackSourcesDataFmt(sData) {
                var sss = [];
                sData.forEach(function(item, i) {
                    sss.push({
                        value: item,
                        itemStyle: { color: that.currentColor}
                    });
                });
                return sss;
            }
            myChart = echarts.init(document.getElementById('street-chart'))
            let option = {
                title: {
                    text: '单位(KG)',
                    textStyle: {
                        color: '#fff',
                        fontSize: 13
                    },
                    right: 0
                },
                tooltip: {
                show: true,
                textStyle: {
                    fontSize: 16
                },
            },
            legend: {
                show: false
            },
            grid: {
                left: 100,
                top: '15%',
                right: '18%',
                bottom: '1%'

            },
            dataZoom: [{
                type: 'slider',
                yAxisIndex: [0,1],
                zoomLock: true,
                width: 4,
                handleSize: 0,
                showDetail: false,
                start: 0,
                end: 30,
                handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
                handleSize: '100%',
                handleStyle: {
                    color: "#d3dee5",
                },
                borderColor: "#90979c"
            }, {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: 0,
                start: 0,
                end: 50,
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true
            }],
            xAxis: {
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                }

            },
            yAxis: {
                type: 'category',
                inverse: true,
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisPointer: {
                    label: {
                        show: true,
                        margin: 10
                    }
                },
                data: attackSourcesName,
                axisLabel: {
                    margin: 90,
                    fontSize: 14,
                    align: 'left',
                    color: '#fff',
                    rich: {},
                    formatter: function(value, index) {
                        value = value.length > 5 ? (value.substring(0, 6)  + '...') : value
                        return value;
                    }
                }
            },
            series: [{
                    z: 2,
                    //name: 'value',
                    type: 'bar',
                    barWidth: '8px',
                    animationDuration: 1000,
                    data: attackSourcesDataFmt(attackSourcesData),
                    itemStyle: {
                        normal: {
                            // color: function(params) {
                            //     return '12'
                            // },
                            barBorderRadius: 0,
                        }
                    },
                    label: {
                        show: true,
                        position: 'right',
                        color: '#fff',
                        fontSize: 14,
                        offset: [10, 0]
                    }
                }

            ]
                    }
            myChart.setOption(option)
        }
    }
}
</script>

<style lang="scss">
.left-bottom-wrapper{
    width: 100%;
    height: 100%;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .operate-line{
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .active{
            color: #fff;
            background: rgba(38, 86, 202, 1);
            width: 68px;
            text-align: center;
        }
        .tag-block{
            width: 68px;
            text-align: center;
            &:hover{
                cursor: pointer;
            }
        }
    }
}
</style>