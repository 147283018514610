<template>
    <div class="center-actual-con">
        <table id="customers">
        <thead>
            <tr>
                <th style="width: 100px;">垃圾类别</th>
                <th style="width: 120px;">垃圾重量(KG)</th>
                <th>采集时间</th>
                <th  style="width: 120px;">小区名称</th>
            </tr>
        </thead>
        </table>
        <div class="inset-table">
            <table id="customers">
            <tbody style="text-align:center" v-if="tableData.length > 0">
                <tr v-for="(item, index) in tableData" :key="index" >
                    <td style="width: 100px;">{{item.typeName}}</td>
                    <td style="width: 120px;">{{item.weight}}</td>
                    <td>{{item.collectTime}}</td>
                    <td style="width: 120px;">{{item.centerName}}</td>
                </tr>
            </tbody>
            <tbody style="text-align:center" v-else>
                <div class="empty-data">暂无数据</div>
            </tbody>
            </table>
             <div class="scrollbar"></div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import DATA_CONFIG from '../../../config/dataConfg'
import { getTodayRealApi } from '@/api/today'
import { getAuthModuleCode } from '@/utils/auth'
export default {
    props: {
        moduleCode: {
            type:String,
            defalut: ''
        }
    },
    data() {
        return {
            testData: {
                type: '可回收物',
                weight: 120,
                time: '2021-3-31 15:00:00',
                center: '三元四村'
            },
            tableData: []
        }
    },
    mounted() {
        setTimeout(() => {
            this.getTodayReal()
            setInterval(() => {
                this.getTodayReal()
            }, 10000)
        }, 200)
    },
    methods: {
        getTodayReal() {
            this.$message.loading('加载中..', 2)
            let tmp = this.moduleCode
            getTodayRealApi({moduleCode: tmp}).then(res=> {
                this.tableData = res.result
            }).catch(() => {}).finally(() => {})
        }
    },
    // computed: mapState({
    //     regionName: state => state.user.module.regionName,
    //     moduleCode: state => state.user.module.moduleCode
    // })
}
</script>

<style lang="scss">
.center-actual-con{
    // background: #2656CA;
    width: 100%;
    height: 100%;
    #customers {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    .inset-table{
        height: 90%;
        overflow: auto;
        
    }
    .empty-data{
        width: 100%;
        min-height: 400px;
        font-size: 18px;
        color: aliceblue;
        padding-top: 40%;
    }
    .scrollbar {
        width : 30px;
        height: auto;
        margin: 0 auto;
    }
    .inset-table::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
    .inset-table::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 15px;
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background   : #1A51B7;
    }
    .inset-table::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background   : #ededed;
    }
 #customers th {
  border: 2px solid #070F39;
  padding: 5px;
}
#customers td{
   border: none;
   padding: 1px;
   color: #fff;
   font-size: 14px;
   line-height: 19px;
}

#customers tr:nth-child(even){background-color: transparent; height: 38px;}

#customers tr {background: rgba(255, 255, 255, 0.14); height: 38px;}

#customers th {
//   padding-top: 12px;
//   padding-bottom: 12px;
  text-align: left;
  background-color: rgba(38, 86, 202, 0.6);
  color: white;
  font-size: 14px;
  height: 28px;
}
}
</style>