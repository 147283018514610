<template>
    <div class="col-number-single">
          <count-to :startVal="0" :endVal="number" :duration="2000" class="count" :decimals="2"/>
          <span class="title">{{title}}</span>
    </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
    components: {
        countTo
    },
    props: {
        number: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            defalut: ''
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.col-number-single{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .count{
        color: #00FBFF;
        font-size: 28px;
    }
    .title{
        color: #fff;
        margin: 10px;
    }
}
</style>