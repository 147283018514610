<template>
    <div class="right-bottom-wrapper">
        <a-row style="width: 100%;height: 50px;color: #fff;font-size: 16px;">
            <a-col :span="24">垃圾采集累计 <count-to :startVal="0" :endVal="totalWeight" :duration="2000" class="count" :decimals="2"/>KG</a-col>
        </a-row>
        <a-row style="width: 100%;" :gutter="20">
            <a-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" v-for="(item,index) in chartData" :key="index">
                <a-row :gutter="20" style="margin-top: 20px;">
                    <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
                        <a-progress type="circle" :percent="item.percentage" :width="80" :strokeColor="color[index]"/>
                    </a-col>
                    <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" style="margin-top: 7%;color: #fff;">
                        {{item.typeName}}<br/>
                        <span style="font-size: 16px;">{{(item.weight > 10000) ? (item.weight * 0.001).toFixed(2) + '吨' : item.weight + 'KG'}}</span>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import countTo from 'vue-count-to'
import DATA_CONFIG from '../../../config/dataConfg'
import { getTypeDataApi } from '@/api/today'
export default {
    components: { countTo },
    data() {
        return{
            totalWeight: 0.00,
            color: [],
            chartData: []
        }
    },
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.getTypeData()
            setInterval(() => {
                this.getTypeData()
            }, 10000)
        }, 200)
    },
    methods: {
        getTypeData() {
            getTypeDataApi({moduleCode: this.moduleCode, time: 'month'}).then(res => {
                this.totalWeight = res.result.totalWeight
                this.chartData = [...res.result.typeData]
                this.chartData.forEach(item => {
                    item.percentage = parseInt(item.percentage)
                })
                this.color = (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors.slice(1,6) : DATA_CONFIG.colors.slice(1,6)
            })
            .catch(() => {
                this.chartData = [{
                    percentage: '0',
                    typeName: '厨余垃圾',
                    weight: 0
                }, {
                    percentage: '0',
                    typeName: '其他垃圾',
                    weight: 0
                }, {
                    percentage: '0',
                    typeName: '可回收物',
                    weight: 0
                }, {
                    percentage: '0',
                    typeName: '有害垃圾',
                    weight: 0
                }]
                this.totalWeight = 0
                
            })
        }
    }
}
</script>

<style lang="scss">
.right-bottom-wrapper{
    width: 100%;
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
    align-items: center;
    .ant-progress-circle .ant-progress-text{
        color: #fff;
    }
}
</style>