<template>
    <div class="data-visual-container">
        <!-- <a-row :gutter="20" style="position: absolute;right: 10px;top:10px;width: 25%;">
            <a-col style="color:#00FBFF;font-size: 16px;cursor:pointer;" @click="toggleSys">
                切换地图看板
                <a-icon type="arrow-right" ></a-icon>
            </a-col>
        </a-row> -->
        <a-row :gutter="30" style="height: 59%;">
            <a-col :xl="7" :lg="24" :md="24" :sm="24" :xs="24" class="col-com-height">
                <com-box title="今日小区垃圾处理实时数据" type="left-top" :moduleCode="moduleCode"></com-box>
            </a-col>
            <a-col :xl="11" lg="24" :md="24" :sm="24" :xs="24" class="col-com-height"><com-box :title="`${regionName}垃圾分类计量系统`" type="center-top" :moduleCode="moduleCode" :regionName="regionName"/></a-col>
            <a-col :xl="6" lg="24" :md="24" :sm="24" :xs="24" class="col-com-height"><com-box title="今日处理计数分析" type="right-top" :moduleCode="moduleCode" :regionName="regionName"/></a-col>
        </a-row>
        <a-row :gutter="30" style="height: 36%;margin-top: 30px;">
            <a-col :xl="7" class="col-com-height"><com-box title="今日街道数据" type="left-bottom" :moduleCode="moduleCode" :regionName="regionName"/></a-col>
            <a-col :xl="11" class="col-com-height"><com-box title="本月垃圾采集趋势图" type="center-bottom" :moduleCode="moduleCode" :regionName="regionName"/></a-col>
            <a-col :xl="6" class="col-com-height"><com-box title="本月垃圾采集统计" type="right-bottom" :moduleCode="moduleCode" :regionName="regionName"/></a-col>
        </a-row>
    </div>
</template>
<script>
import {queryModule} from "@/api/region";
import {mapState} from "vuex";
import CenterActualData from './component/centerActualData.vue'
import comBox from './component/comBox.vue'
import toolsBox from './component/toolsBox.vue'
export default {
    components: { comBox,  CenterActualData, toolsBox },
    data() {
        return {
            regionName: '',
            moduleCode: this.$route.query.moduleCode,
        }
    },
    mounted() {
        this.$getCurrentModuleInfo(this.moduleCode).then(res => {
            this.regionName = res.regionName
        })
    },
    methods: {
        toggleSys() {
            // this.$router.push({path: '/viewport/areaMapView', query: {moduleCode: this.moduleCode, regionName: this.regionName}})
            this.$router.push({path: '/viewport/areaMapView'})

        }
    },
}
</script>

<style lang="scss">
@import '@/assets/variableCss.scss';
.data-visual-container{
    width: 100%;
    height:100%;
    box-sizing: border-box;
    // overflow: hidden;
    background: $global-background-color;
    padding: 50px 30px 10px 30px;
    position: relative;
    .col-com-height{
        height: 100%;
    }
    .setting-icon{
        position: absolute;
        top: 10px;
        color: #00FBFF;
        width: 40px;
        height: 40px;
    }
    .toggle-btn{
        width: 200px;
        height:30px;
        color: #00FBFF;
        font-size: 15px !important;
        position: absolute;
        top: 5px;
        // float: right;
    }
}
@media screen and (max-width: 1000px) {
    body {
        //  height:100%;
        background-color:$global-background-color;
    }
}
</style>