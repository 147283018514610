<template>
    <div class="main-statistics-con">
        <a-row class="title" style="font-size: 16px;">
            <a-col span="12">今日已采集统计 <span style="margin-left: 10px">{{todayTime}}</span></a-col>
            <a-col span="12" style="text-align:right;">在线设备数量<span style="margin-left: 10px">{{totalData.onlineDevice || 0}}</span></a-col>
        </a-row>
        <a-row :gutter="20" class="number-row">
            <a-col :xl="4.1" :lg="8" :md="12" :xs="24">
                <com-number-box :number="totalData.totalWeight" title="垃圾采集重量(KG)"></com-number-box>
            </a-col>
            <a-col :xl="4" :lg="8" :md="12" :xs="24">
                <com-number-box :number="totalData.kitchenWaste" title="厨余垃圾(KG)"></com-number-box>
            </a-col>
            <a-col :xl="4" :lg="8" :md="12" :xs="24">
                <com-number-box :number="totalData.other" title="其他垃圾(KG)"></com-number-box>
            </a-col>
            <a-col :xl="4" :lg="8" :md="12" :xs="24">
                <com-number-box :number="totalData.recyclable" title="可回收物(KG)"></com-number-box>
            </a-col>
            <a-col :xl="4" :lg="8" :md="12" :xs="24">
                <com-number-box :number="totalData.harmful" title="有害垃圾(KG)"></com-number-box>
            </a-col>
        </a-row>
        <div class="divide-line"> <div class="box-left"></div><div class="box-right"></div></div>
        <div style="width: 100%;height: 300px;" id="chartCon">
            <div  id="chart" ></div>
        </div>

    </div>
</template>
<script>
import DATA_CONFIG from '../../../config/dataConfg'
import { getTodayReceivedApi, getTodayReceivedPerHourApi } from '@/api/today.js'
import * as echarts from 'echarts';
import comNumberBox from './comNumberBox'
import moment from 'moment'
export default {
    components: { comNumberBox },
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        regionName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            colors: [],
            type: 'all',
            index: 0,
            typeName: '总量',
            totalData: {
                harmful: 0.00,
                kitchenWaste: 0.00,
                onlineDevice: 0,
                other: 0.00,
                recyclable: 0.00,
                totayTime: '',
                totalWeight: 0.00

            },
            chart: null,
            chartData: {
                harmful: [0,0,0,0,0,0,0],
                kitchenWaste: [0,0,0,0,0,0,0],
                other: [0,0,0,0,0,0,0],
                recyclable: [0,0,0,0,0,0,0],
                total: [0,0,0,0,0,0,0]
            },
            todayTime: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
            timer: ''
        }
    },
    mounted() {
        this.colors = (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors : DATA_CONFIG.colors
        console.log(this.colors)
        setTimeout(() => {
            this.getTodayReceived()
            this.getTodayReceivedPerHour()
            setInterval(() => {
                this.getTodayReceived()
                this.getTodayReceivedPerHour()
            }, 10000)
        }, 200)
        let that = this
        this.timer = setInterval(() => {
            that.todayTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }, 1000)
    },
    beforeDestroy:function(){
        if(this.timer){
            clearInterval(this.timer);
        }
    },
    methods: {
        toggle(type, i, name) {
            this.type = type
            this.index = i
            this.typeName = name
        },
        getTodayReceivedPerHour() {
            getTodayReceivedPerHourApi({moduleCode: this.moduleCode}).then(res => {
                this.chartData = {...res.result}
                this.drawChart()
            }).catch(() => {
                this.$message.error('数据接收异常')
                this.chartData = {
                    harmful: [0,0,0,0,0,0,0],
                    kitchenWaste: [0,0,0,0,0,0,0],
                    other: [0,0,0,0,0,0,0],
                    recyclable: [0,0,0,0,0,0,0],
                    total: [0,0,0,0,0,0,0]
                }
                this.drawChart()
            })
        },
        getTodayReceived() {
            getTodayReceivedApi({moduleCode: this.moduleCode}).then(res => {
                this.totalData = {...res.result}
            })
        },
        drawChart() {
            var chartDom = document.getElementById('chartCon');
            var myChart = echarts.init(chartDom);
            myChart.setOption({
                color: (this.regionName === '常熟市' || this.regionName === '昆山市') ? DATA_CONFIG.cs_colors : DATA_CONFIG.colors,
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['总量', '厨余垃圾', '其他垃圾', '可回收物', '有害垃圾', ],
                    padding: [30,0,0,0,],
                    textStyle: {
                        color: '#fff',
                        fontSize: 15
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '30%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00','14:00','15:00','16:00', '17:00', '18:00','19:00','20:00','21:00','22:00','23:00','24:00'],
                    splitLine: {
                        show:false,
                        lineStyle: {
                            color:"#fff",
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: this.colors[1],
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',
                            fontSize: 15

                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show:false,
                        lineStyle: {
                            color:["#ccc"],
                            type: 'dashed'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#fff',
                            fontSize: 15

                        }
                    },
                },
                series: [
                    {
                        name: '总量',
                        type: 'line',
                        data: this.chartData.total
                    },
                    {
                        name: '厨余垃圾',
                        type: 'line',
                        data: this.chartData.kitchenWaste
                    },
                    {
                        name: '其他垃圾',
                        type: 'line',
                        data: this.chartData.other
                    },
                    {
                        name: '可回收物',
                        type: 'line',
                        data: this.chartData.recyclable
                    },
                    {
                        name: '有害垃圾',
                        type: 'line',
                        data: this.chartData.harmful
                    },
                ]
            })
        }
    }
}
</script>
<style lang="scss">
.main-statistics-con{
    width: 100%;
    height: 100%;
    .number-row{
        padding: 20px;
        text-align: center;
    }
    .title{
        color: #fff;
        margin: 10px;
        // font-size: 16px;
    }
    .col-number-single .count{
        font-size: 25px;
    }
    .operate-line{
        width: 100%;
        height: 35px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        .active{
            color: #fff;
            background: rgba(38, 86, 202, 1);
            width: 68px;
            text-align: center;
        }
        .tag-block{
            width: 68px;
            text-align: center;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .divide-line{
        width: 100%;
        height: 1px;
        background: rgba(26, 81, 183, 0.63);
        position: relative;
        .box-left{
            width: 26px;
            height: 26px;
            position: absolute;
            bottom: -1.5px;
            left: -1.5px;
            border-bottom: 2px solid #00FBFF;
        }
        .box-right{
            width: 26px;
            height: 26px;
            position: absolute;
            bottom: -1.5px;
            right: -1.5px;
            border-bottom: 2px solid #00FBFF;
        }
    }
    .gcharts{
        width:100%;
        height:100%;
    }
}
</style>